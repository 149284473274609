<template>
  <div class="performance-field">
    <NormForm
      v-model="queryForm"
      :query-btn-loading="tableLoading"
      @reset="handleQueryReset"
      @query="handleQueryForm"
    >
      <el-form-item label="">
        <el-input v-model="queryForm.performanceRuleName" placeholder="规则名称" clearable />
      </el-form-item>
      <el-form-item label="">
        <NormSelection
          v-model="queryForm.performanceRuleStatus"
          :select-options="statusOptions"
          placeholder="状态"
        />
      </el-form-item>
      <el-form-item label="">
        <NormSelection
          v-model="queryForm.timeSelect"
          width="120"
          :select-options="timeOptions"
          :clearable="false"
          placeholder="时间选项"
        />
        <el-date-picker
          v-model="queryForm.time"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期时间"
        />
      </el-form-item>
    </NormForm>
    <vxe-toolbar>
      <template v-slot:buttons>
        <el-button type="primary" @click="handleAdd">新建</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      v-loading="tableLoading"
      :show-overflow="false"
      class="editTable"
      max-height="550px"
      align="center"
      :data="tableData"
    >
      <vxe-table-column field="performanceRuleName" title="规则名称" min-width="100" />
      <vxe-table-column field="performanceRuleRemark" title="规则说明" min-width="100" />
      <vxe-table-column field="effectDate" title="生效时间" min-width="100" />
      <vxe-table-column field="status" title="状态" min-width="100" />
      <vxe-table-column field="createByName" title="添加人员" min-width="100" />
      <vxe-table-column field="createTime" title="新增时间" min-width="200" />
      <vxe-table-column title="操作" min-width="100">
        <template v-slot="{row}">
          <el-button type="text" @click="handleView(row)">查看</el-button>
          <el-button type="text" @click="handleCopy(row)">复制</el-button>
          <el-button v-if="row.performanceRuleStatus !== 0" type="text" @click="handleDisable(row)">禁用</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <Paging style="float: right" class="my-2" :pager="pager" end @update="pagerUpdate" />
    <el-dialog
      title="新增字段"
      :visible.sync="visible"
      width="500px"
    >
      <el-form ref="addForm" :model="addForm" :rules="addFormRules">
        <el-form-item label="字段名称">
          <el-input v-model="addForm" />
        </el-form-item>
        <el-form-item label="字段名称">
          <el-input v-model="addForm" />
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="visible = false">OK</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import NormForm from '@/components/NormForm'
import NormSelection from '@/components/NormSelection'
import Paging from '@/components/Paging'
import { getPerformanceRuleByPage, unablePerformanceRule } from '@/api/performance'
export default {
  components: {
    NormForm,
    NormSelection,
    Paging
  },
  data() {
    const statusOptions = [{ value: 0, label: '已禁用' }, { value: 1, label: '生效中' }, { value: 2, label: '待生效' }]
    const timeOptions = [{ value: 0, label: '生效时间' }, { value: 1, label: '新增时间' }]
    return {
      queryForm: {
        performanceRuleName: '',
        performanceRuleStatus: '',
        timeSelect: 0,
        time: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableLoading: false,
      visible: false,
      tableData: [],
      addForm: {},
      addFormRules: {},
      statusOptions,
      timeOptions
    }
  },
  computed: {
    getPerformanceRuleByPageParams() {
      const time = this.queryForm.timeSelect === 0
        ? { effectDate: this.queryForm.time }
        : { createTime: this.queryForm.time }
      return Object.assign({}, this.queryForm, this.pager, time)
    }
  },
  mounted() {
    this._getPerformanceRuleByPage()
  },
  methods: {
    handleQueryReset() {
      Object.assign(this.queryForm, this.$options.data().queryForm)
    },
    handleQueryForm() {
      this.pager.current = 1
      this._getPerformanceRuleByPage()
    },
    handleAdd() {
      this.$router.push({ path: 'add', append: true, query: { type: 'add' }})
    },
    handleView({ performanceRuleId }) {
      this.$router.push({ path: 'details', append: true, query: { type: 'view', performanceRuleId }})
    },
    handleCopy({ performanceRuleId }) {
      this.$router.push({ path: 'add', append: true, query: { type: 'copy', performanceRuleId }})
    },
    pagerUpdate(val) {
      this.pager = val
      this._getPerformanceRuleByPage()
    },
    handleDisable({ performanceRuleId }) {
      this.$confirm('是否确认禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { msg } = await unablePerformanceRule({ performanceRuleId })
        this.$message.success(msg)
        this._getPerformanceRuleByPage()
      }).catch(() => {})
    },
    async _getPerformanceRuleByPage() {
      try {
        this.tableLoading = true
        const { datas: { pager, records }} = await getPerformanceRuleByPage(this.getPerformanceRuleByPageParams)
        this.tableData = records.map(item => {
          const status = item.performanceRuleStatus === 0 ? '禁用'
            : item.performanceRuleStatus === 1 ? '生效中'
              : '待生效'
          return { ...item, status }
        })
        this.pager = pager
      } finally {
        this.tableLoading = false
      }
    }
  }
}
</script>

<style>

</style>
